import { Col, Container, Row } from "react-bootstrap";

import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import React from "react";
import aboutimg from "../assets/img/aboutimg.png";
import aboutimg2 from "../assets/img/aboutimg2.png";
import bannerimg from "../assets/img/banner_img.png";
import bannerlogo from "../assets/img/banner_logo.svg";
import logo2 from "../assets/img/logo2.svg";
import workPatternimg from "../assets/img/workPattern_img.png";

export default function Home() {
  return (
    <React.Fragment>
      <section className="banner_section" id="home">
        <Container>
          <Row className="align-items-center">
            <Col md={7}>
              <div className="banner_content">
                <img className="banner_logo" src={bannerlogo} alt="" />
                <div className="downloadApp_box">
                  <Link className="Appdownload_btn">
                    <Icon icon="basil:apple-solid" />
                    <div className="Appdownload_cont">
                      <h4>Download App</h4>
                      <p>From App Store</p>
                    </div>
                  </Link>
                  <Link className="Appdownload_btn android_btn">
                    <Icon icon="icomoon-free:android" />
                    <div className="Appdownload_cont">
                      <h4>Download App</h4>
                      <p>From Play Store</p>
                    </div>
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={5} className="textsm_center">
              <div className="banner_img">
                <img src={bannerimg} alt="" />
                <div id="about"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="Home_aboutus">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} md={9}>
              <div className="HomeAbout_img">
                <img className="aboutimg1" src={aboutimg} alt="" />
                <img className="aboutimg2" src={aboutimg2} alt="" />
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="HomeAbout_cont">
                <h3 className="main_heading fw_200">The Fractional Coach</h3>
                <p>
                  Amy Crutchfield brings over 20 years of expertise in advising
                  clients and talent and building exceptional leadership teams.
                  She is dedicated to providing you with timely insights and
                  solutions to your pressing career questions. Amy has been
                  running her executive search firm for over 22 years,
                  representing Fortune 500 companies, start-ups, renowned
                  consultancies, and agencies, and has amassed a wealth of
                  knowledge and experience to help you at any stage of your
                  career, whether you’re just starting out, looking to pivot,
                  dealing with a layoff or just feeling stuck. Her passion lies
                  in providing you with insights, clarity, and the steps you
                  need to take to achieve your career aspirations.
                </p>
                <ul>
                  <li>
                    Popular topics include:
                    <ul>
                      <li>
                        Resume critique, tailoring a resume for a specific role
                      </li>
                      <li>Review of cover letters and thank you notes</li>
                      <li>Current job market conditions</li>
                      <li>Increasing responses to job applications</li>
                      <li>Needing clarity in your narrative/pitch</li>
                      <li>Virtual interview techniques</li>
                      <li>Managing multiple offers and salary negotiation</li>
                      <li>Navigating your career trajectory</li>
                      <li>Freelance vs. Full-Time</li>
                      <li id="how_it_works">
                        Discussing a layoff, gap in working
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="HowItWorks_sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="mainheading_div">
                <h3 className="main_heading HowItWork_heading text-center text_dark text-uppercase">
                  How It Works
                </h3>
              </div>
            </Col>
            <Col md={4} className="position-relative">
              <img className="workPattern_img" src={workPatternimg} alt="" />
              <div className="HowItWorks_div">
                <span className="WorkCount_badge">01</span>
                <h3>Onboard The App</h3>
                <p>
                  Simply download and log in to <br /> The Fractional Coach app
                  using your email or social account. It’s quick, easy, and
                  secure
                </p>
              </div>
            </Col>
            <Col md={4} className="position-relative">
              <img className="workPattern_img" src={workPatternimg} alt="" />
              <div className="HowItWorks_div">
                <span className="WorkCount_badge">02</span>
                <h3>Select Package</h3>
                <p>
                  Choose from flexible packages that fit your needs. Whether you
                  want a quick chat or extended sessions, you have full control
                  over how much time you use, with no long-term commitment.
                </p>
              </div>
            </Col>
            <Col md={4} className="position-relative">
              <div className="HowItWorks_div">
                <span className="WorkCount_badge">03</span>
                <h3>Chat With Experts</h3>
                <p>
                  Connect with a career coach expert via chat. Get tailored
                  advice, ask questions, and receive actionable insights—right
                  when you need them.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="downloadApp_sec">
        <Container>
          <Row className="align-items-center">
            <Col md={7}>
              <div className="downloadApp_info">
                <h3 className="main_heading">Download Our Mobile App</h3>
                <p>
                  Download the app now and start your career guidance from the
                  experts.
                </p>
                <div className="downloadApp_box">
                  <Link className="Appdownload_btn">
                    <Icon icon="basil:apple-solid" />
                    <div className="Appdownload_cont">
                      <h4>Download App</h4>
                      <p>From App Store</p>
                    </div>
                  </Link>
                  <Link className="Appdownload_btn android_btn">
                    <Icon icon="icomoon-free:android" />
                    <div className="Appdownload_cont">
                      <h4>Download App</h4>
                      <p>From Play Store</p>
                    </div>
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={5}>
              <div className="downloadLogo_box">
                <img className="downloadLogo_img" src={logo2} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
